var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('home-top'),_c('a-row',{attrs:{"gutter":[8,16]}},[_c('a-col',{attrs:{"span":8}},[_c('a-card',{attrs:{"bordered":false}},[_c('template',{slot:"title"},[_vm._v(" 待调度工单 "),_c('a-badge',{attrs:{"count":"4","number-style":{
                backgroundColor: '#fff',
                color: '#999',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
              }}})],1),_c('a-table',{attrs:{"scroll":{ y: 460 },"columns":_vm.columns,"data-source":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading},scopedSlots:_vm._u([{key:"_index",fn:function(text, record, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"type",fn:function(text, record){return [(record.type==='shangbao')?_c('a-tag',{attrs:{"color":"cyan"}},[_vm._v("上报")]):_vm._e(),(record.type==='xunjian')?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("巡检")]):_vm._e(),(record.type==='qita')?_c('a-tag',{attrs:{"color":"purple"}},[_vm._v("其他")]):_vm._e()]}},{key:"state",fn:function(text, record){return [(record.state==='UN_PROCESS')?_c('a-tag',[_vm._v("未分配")]):_vm._e(),(record.state==='PROCESSING')?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("已分配")]):_vm._e()]}},{key:"action",fn:function(text, record){return [_c('a',{on:{"click":[function($event){return _vm.handleClick(record.id)},function($event){$event.stopPropagation();}]}},[_vm._v("调度")])]}}])})],2)],1),_c('a-col',{attrs:{"span":16}},[_c('a-card',{attrs:{"bordered":false}},[_c('template',{slot:"title"},[_vm._v(" 处理中的工单 "),_c('a-badge',{attrs:{"count":"20","number-style":{
                backgroundColor: '#fff',
                color: '#999',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
              }}})],1),_c('a-table',{attrs:{"scroll":{ y: 460 },"columns":_vm.columns,"data-source":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading},scopedSlots:_vm._u([{key:"_index",fn:function(text, record, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"type",fn:function(text, record){return [(record.type==='shangbao')?_c('a-tag',{attrs:{"color":"cyan"}},[_vm._v("上报")]):_vm._e(),(record.type==='xunjian')?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("巡检")]):_vm._e(),(record.type==='qita')?_c('a-tag',{attrs:{"color":"purple"}},[_vm._v("其他")]):_vm._e()]}},{key:"state",fn:function(text, record){return [(record.state==='UN_PROCESS')?_c('a-tag',[_vm._v("未分配")]):_vm._e(),(record.state==='PROCESSING')?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("已分配")]):_vm._e()]}},{key:"action",fn:function(text, record){return [_c('a',{on:{"click":[function($event){return _vm.handleClick(record.id)},function($event){$event.stopPropagation();}]}},[_vm._v("调度")])]}}])})],2)],1),_c('a-col',{attrs:{"span":8}},[_c('a-card',{attrs:{"bordered":false}},[_c('template',{slot:"title"},[_vm._v(" 空闲车辆 "),_c('a-badge',{attrs:{"count":"4","number-style":{
                backgroundColor: '#fff',
                color: '#999',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
              }}})],1),_c('a-table',{attrs:{"columns":_vm.columns,"scroll":{ y: 460 },"data-source":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading},scopedSlots:_vm._u([{key:"_index",fn:function(text, record, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"type",fn:function(text, record){return [(record.type==='shangbao')?_c('a-tag',{attrs:{"color":"cyan"}},[_vm._v("上报")]):_vm._e(),(record.type==='xunjian')?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("巡检")]):_vm._e(),(record.type==='qita')?_c('a-tag',{attrs:{"color":"purple"}},[_vm._v("其他")]):_vm._e()]}},{key:"state",fn:function(text, record){return [(record.state==='UN_PROCESS')?_c('a-tag',[_vm._v("未分配")]):_vm._e(),(record.state==='PROCESSING')?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("已分配")]):_vm._e()]}},{key:"action",fn:function(text, record){return [_c('a',{on:{"click":[function($event){return _vm.handleClick(record.id)},function($event){$event.stopPropagation();}]}},[_vm._v("调度")])]}}])})],2)],1)],1),_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":6}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }